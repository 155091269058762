import React from 'react';
import { Link } from 'gatsby';
import grid from '../cssmodules/grid.module.scss';
import styles from '../cssmodules/navbar.module.scss';

function Navbar() {
  return (
    <nav className={styles.navbar}>
      <div className={grid.container}>
        <div className={styles.wrapper}>
          <h1 className={styles.siteTitle}>
            <Link to="/">Superbia</Link>
          </h1>
          <ul className={styles.menu}>
            <li>
              <Link activeClassName="is-active" to="/about">
                About
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;

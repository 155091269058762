import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Markdown from 'markdown-to-jsx';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Section from '../components/Section';
import TitleBlock from '../components/TitleBlock';
import Profile from '../components/Profile';
import OffsetBlock from '../components/OffsetBlock';
import Contact from '../components/Contact';
import StackList from '../components/StackList';

function AboutPage({ data }) {
  const frontMatter = data.allMarkdownRemark.edges[0].node.frontmatter;
  const {
    seo: { title: seoTitle, description: seoDescription },
  } = frontMatter;
  const { page, services, people } = frontMatter;
  return (
    <Layout>
      <SEO title={seoTitle} description={seoDescription} />
      <Helmet>
        <body className="bgColor bgColor--dawnPink" />
      </Helmet>
      <Section large>
        <TitleBlock title={page.title}>
          <Markdown>{page.lead}</Markdown>
        </TitleBlock>
      </Section>
      <Section>
        <OffsetBlock title={services.title}>
          <StackList list={services.list} />
        </OffsetBlock>
      </Section>
      <Section>
        <OffsetBlock title={people.title}>
          <Profile profiles={people.profiles} />
        </OffsetBlock>
      </Section>
      <Section>
        <OffsetBlock title="Contact">
          <Contact />
        </OffsetBlock>
      </Section>
    </Layout>
  );
}

AboutPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }).isRequired,
};

export default AboutPage;

export const query = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { slug: { eq: "about" } } }) {
      edges {
        node {
          frontmatter {
            seo {
              title
              description
            }
            page {
              title
              lead
            }
            services {
              title
              list {
                title
                description
              }
            }
            people {
              title
              profiles {
                name
                bio
                image {
                  childImageSharp {
                    fluid(
                      maxWidth: 720
                      sizes: "(max-width: 720px) 50vw, 720px"
                      duotone: { highlight: "#e6dcd0", shadow: "#000000" }
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                imageAlt
              }
            }
          }
        }
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import grid from '../cssmodules/grid.module.scss';
import styles from '../cssmodules/offsetBlock.module.scss';

function OffsetBlock({ title, children }) {
  return (
    <div className={styles.wrapper}>
      <h2 className={`${styles.title} dogTag`}>{title}</h2>
      <div className={grid.row}>
        <div className={`${grid.colMd9} ${grid.colMdOffset3}`}>{children}</div>
      </div>
    </div>
  );
}

OffsetBlock.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default OffsetBlock;

import React from 'react';
import grid from '../cssmodules/grid.module.scss';
import styles from '../cssmodules/footer.module.scss';

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div className={grid.container}>
      <div className={styles.wrapper}>
        <p>&copy; Superbia</p>
        <p>{currentYear}</p>
      </div>
    </div>
  );
}

export default Footer;

import React from 'react';
import PropTypes from 'prop-types';
import styles from '../cssmodules/section.module.scss';

function Section({ children, large }) {
  const classes = large
    ? `${styles.section} ${styles.sectionLarge}`
    : `${styles.section}`;
  return <div className={classes}>{children}</div>;
}

Section.defaultProps = {
  large: false,
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  large: PropTypes.bool,
};

export default Section;

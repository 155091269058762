import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import grid from '../cssmodules/grid.module.scss';
import styles from '../cssmodules/profile.module.scss';

function Profile({ profiles }) {
  return profiles.map(({ name, bio, image, imageAlt }) => (
    <div key={name} className={`${styles.profile} content`}>
      <div className={grid.row}>
        <div className={grid.colMd7}>
          <h3 className="heading3">{name}</h3>
          <p>{bio}</p>
        </div>
        <div className={`${grid.colMd4} ${grid.colMdOffset1}`}>
          <Img fluid={image.childImageSharp.fluid} alt={imageAlt} />
        </div>
      </div>
    </div>
  ));
}

Profile.propTypes = {
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Profile;

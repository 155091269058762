import React from 'react';
import PropTypes from 'prop-types';
import styles from '../cssmodules/titleBlock.module.scss';
import grid from '../cssmodules/grid.module.scss';

function TitleBlock({ title, children }) {
  return (
    <div className={styles.titleBlock}>
      <div className={grid.row}>
        <div className={grid.colMd5}>
          <h2 className="heading1">{title}</h2>
        </div>
        <div className={`${grid.colMd7} textLead`}>{children}</div>
      </div>
    </div>
  );
}

TitleBlock.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default TitleBlock;

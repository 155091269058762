import React from 'react';
import styles from '../cssmodules/contact.module.scss';

function Contact() {
  return (
    <div className={styles.contact}>
      <p>
        Level 7, 388 Bourke Street, VIC 3000
        <br />
        0422 411 230
        <br />
        <a href="&#x6D;&#97;&#x69;&#x6C;&#116;&#111;:&#104;&#101;&#x6C;&#x6C;&#111;&#64;&#x73;&#117;&#x70;&#101;&#x72;&#98;&#105;&#x61;&#46;&#99;&#x6F;&#x6D;&#46;&#x61;u">
          &#104;&#101;&#x6C;&#x6C;&#111;&#64;&#x73;&#117;&#x70;&#101;&#x72;&#98;&#105;&#x61;&#46;&#99;&#x6F;&#x6D;&#46;&#x61;u
        </a>
      </p>
    </div>
  );
}

export default Contact;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Markdown from 'markdown-to-jsx';
import grid from '../cssmodules/grid.module.scss';
import styles from '../cssmodules/stackList.module.scss';
import btn from '../cssmodules/btn.module.scss';

function StackList({ list }) {
  return list.map(({ title, description }) => (
    <div key={title} className={styles.item}>
      <div className={grid.row}>
        <div className={grid.colMd5}>
          <h3 className="heading2">{title}</h3>
        </div>
        <div className={`${grid.colMd7} content`}>
          <Markdown
            options={{
              overrides: {
                Link: {
                  component: Link,
                  props: {
                    className: btn.more,
                  },
                },
              },
            }}
          >
            {description}
          </Markdown>
        </div>
      </div>
    </div>
  ));
}

StackList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
};

export default StackList;

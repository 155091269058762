/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

// Styles.
import '../styles/app.scss';
import grid from '../cssmodules/grid.module.scss';
import styles from '../cssmodules/layout.module.scss';

// Critical js.
import '../scripts/fontLoading';

// Components.
import Navbar from './Navbar';
import Footer from './Footer';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={() => (
      <div className={styles.layout}>
        <Navbar />
        <main className={grid.container}>{children}</main>
        <Footer />
      </div>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
